var init = function() {
  var reveals = document.getElementsByClassName("reveal");
  for (var i = 0; i < reveals.length; i++) {
    (function(i) {
      var reveal = reveals.item(i);
      var summaries = reveal.getElementsByClassName("reveal__summary");
      var fulls = reveal.getElementsByClassName("reveal__full");
      var toggles = reveal.getElementsByClassName("reveal__toggle");

      if (summaries.length == 1 && fulls.length == 1 && toggles.length == 1) {
        var summary = summaries[0];
        var full = fulls[0];
        var toggle = toggles[0];

        toggle.addEventListener(
          "click",
          function(event) {
            event.preventDefault();
            summary.classList.add("reveal__reveal--hidden");
            full.classList.remove("reveal__reveal--hidden");
          },
          false
        );
      }
    })(i);
  }
};
window.addEventListener("load", init, false);
